import { analytics } from '../src/portfolio'

async function getLocation() {
    // Get the user's geolocation
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
    });
}

function isBot() {
    const userAgent = navigator.userAgent.toLowerCase();
    const botUserAgents = [
        'bot',
        'crawl',
        'spider',
        'search',
        'slurp',
        'bingbot',
        'googlebot',
        'yandexbot',
    ];

    for (const botUserAgent of botUserAgents) {
        if (userAgent.includes(botUserAgent)) {
            return true;
        }
    }

    return false;
}

async function logUserDetails() {
    //Log User Location
    let location = "Not Available";
    try {
        const position = await getLocation();
        const { latitude, longitude } = position.coords;
        location = `https://www.google.com/maps/place/${latitude},${longitude}`;
    } catch (error) {
        console.log(error);
    }


    // Get the user's IP address, country name and ISP
    let ip_address = "Not Available";
    let countryName = "Not Available";
    let isp = "Not Available";
    await fetch('https://ipapi.co/json/')
        .then(response => response.json())
        .then(data => {
            const { ip, country_name, org } = data;
            ip_address = ip;
            countryName = country_name;
            isp = org;
        })
        .catch(error => {
            console.log(error);
        });


    // Get the user's user agent
    const userAgent = navigator.userAgent;

    // Get the user's browser
    let browser;
    let browserName;
    let browserVersion;

    // Detect Chrome
    if (userAgent.indexOf("Chrome") > -1) {
        browserName = "Chrome";
        browserVersion = userAgent.substring(userAgent.indexOf("Chrome") + 7, userAgent.indexOf(" ", userAgent.indexOf("Chrome"))).trim();
    }
    // Detect Firefox
    else if (userAgent.indexOf("Firefox") > -1) {
        browserName = "Firefox";
        browserVersion = userAgent.substring(userAgent.indexOf("Firefox") + 8, userAgent.indexOf(" ", userAgent.indexOf("Firefox"))).trim();
    }
    // Detect Internet Explorer
    else if (userAgent.indexOf("MSIE") > -1) {
        browserName = "Internet Explorer";
        browserVersion = userAgent.substring(userAgent.indexOf("MSIE") + 5, userAgent.indexOf(";", userAgent.indexOf("MSIE"))).trim();
    }
    // Detect Edge
    else if (userAgent.indexOf("Edge") > -1) {
        browserName = "Edge";
        browserVersion = userAgent.substring(userAgent.indexOf("Edge") + 5, userAgent.indexOf(" ", userAgent.indexOf("Edge"))).trim();
    }
    // Detect Safari
    else if (userAgent.indexOf("Safari") > -1) {
        browserName = "Safari";
        browserVersion = userAgent.substring(userAgent.indexOf("Version") + 8, userAgent.indexOf(" ", userAgent.indexOf("Version"))).trim();
    }
    // Detect Opera
    else if (userAgent.indexOf("OPR") > -1) {
        browserName = "Opera";
        browserVersion = userAgent.substring(userAgent.indexOf("OPR") + 4, userAgent.indexOf(" ", userAgent.indexOf("OPR"))).trim();
    }
    // Unknown browser
    else {
        browserName = "Unknown Browser";
        browserVersion = "Not Available";
    }

    //Setting Browser String
    if (browserName === "Unknown Browser") {
        browser = "Unknown Browser";
    } else {
        browser = browserName + " " + browserVersion;
    }

    // Get the user's operating system, and device
    const operatingSystem = navigator.platform;
    const deviceType = /Mobile/.test(navigator.userAgent) ? 'Mobile' : 'Desktop';


    // Get the user's battery percentage (if supported by browser)
    let battery;
    var batteryAPI = navigator.battery || navigator.webkitBattery || navigator.mozBattery || navigator.getBattery;

    if (batteryAPI) {
        if (navigator.getBattery) {
            await navigator.getBattery().then(function (batteryInfo) {
                battery = (batteryInfo.level * 100).toString();
                battery = battery + "%";
            });
        }
        else {
            battery = batteryAPI.level * 100;
            battery = battery + "%";
        }
    }
    else {
        battery = "Not Available";
    }


    let message = `Someone visited ${window.location.hostname} 
    \nIP Address: ${ip_address} 
    \nCountry: ${countryName} 
    \nGeoLocation: ${location} 
    \nBrowser: ${browser} 
    \nOperating System: ${operatingSystem} 
    \nDevice: ${deviceType} 
    \nBattery: ${battery} 
    \nUser Agent: ${userAgent} 
    \nISP: ${isp}`;

    const encodedStr = encodeURI(message);

    // Send a WhatsApp message to the developer
    const url = `https://api.callmebot.com/whatsapp.php?phone=${analytics.callMeBotNumber}&text=${encodedStr}&apikey=${analytics.callMeBotAPI}`;

    if (!isBot()) {

        await fetch(url)
            .then(response => {
                // console.log(response);
            })
            .catch(error => {
                console.log(error);
            });

    }
}

export default logUserDetails;
