import React, { useEffect } from "react";
import "./App.css";
import Main from "./containers/Main";
import logUserDetails from "./logUserDetails";

function App() {
  useEffect(() => {
    logUserDetails();
  }, []);
  return (
    <div>
      <Main />
    </div>
  );
}

export default App;
